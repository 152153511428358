// React --------------------------
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Cookie ------------------
import Cookies from 'js-cookie';

//BootStrap -------------------------
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

//My own functions ---------------------------------------
import { setAWSStocklistAction, setPortfolioStatisticsAction } from '../actions/userActions';
import "../CSS/App.css";


// denne funksjonen har knappen for å hente aksjer og trigger funksjonen som trengs for å hente aksjer
const GetStockListButton = () => {

  const dispatch = useDispatch();

  const [state_country, setCountry] = useState('') //Country er hvilket land som skal hentes fra AWS
  const [state_isLoading, setLoading] = useState(false); //isLoading brukes for å gi et laoding symbol når man henter aksjer

  const handleCountrySelect = (country) => {
    setCountry(country);
    Cookies.set('Country', country, { expires: 7 });
  }

  const HandleGetStockList = async () => {
    //console.log("Fetching available stocks from")
    setLoading(true);

    const data = await GetStockList(state_country);
    // console.log(data);
    if (data !== undefined) {

      const dataItems = data.put_body.Items;

      // the dispatch sets the dataitems to the AWS Stocklist object!
      dispatch(setAWSStocklistAction(dataItems));
    }
    setLoading(false);
  };

  useEffect(() => {
    const stored_country = Cookies.get('Country');
    if (stored_country) {
      console.log('Coockie:' + stored_country);
      handleCountrySelect(stored_country);
    }
  }, []);

  //<Button onClick={HandleGetStockList}>Get Stocks</Button>
  return (
    <Container>
      <h3>Collect availabe stocks from a country!</h3>
      <br></br>
      <Row className="justify-content-center">
        <Col xs="auto">
          <DropdownButton id="dropdown-Country" title={state_country || "Select a country"}>
            <Dropdown.Item onClick={() => handleCountrySelect('US')}>USA</Dropdown.Item>
            <Dropdown.Item disabled onClick={() => handleCountrySelect('DE')}>Germany</Dropdown.Item>
          </DropdownButton>
        </Col>
        <Col xs="auto">
          <Button variant="primary" disabled={state_country === ''} onClick={HandleGetStockList}>Get Stocks</Button>
          {state_isLoading ? <Spinner as="span" animation="border" size="lg" /> : <></>}
        </Col>
      </Row>
    </Container>
  );
};

// Denne funksjonen henter akser fra AWS databasen. Bare api ingen JSX
const GetStockList = async (Country) => {

  let postObject = {
    "Task": "SCAN_STOCK",
    "Country": Country
  };
  //console.log(JSON.stringify(postObject));
  try {
    const api_url = process.env.REACT_APP_STOCK_TABLE_API_URL;
    const api_key = process.env.REACT_APP_STOCK_TABLE_API_KEY;
    const response = await fetch(api_url,
      {
        method: 'POST', // or 'POST' or other HTTP methods based on your API
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': api_key
        },
        // Add a request body if needed (for example, for POST requests)
        body: JSON.stringify(postObject)
      });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return (data);

  } catch (error) {
    console.error('Error invoking Lambda function:', error.message);
  }
};

// Denne funksjonen kjører apiet for statistisk analyse, og deretter lagrer resultatet i store
const AnalyzePortfolioButton = () => {
  const dispatch = useDispatch();
  const store_portfolio = useSelector((state) => state.portfolio.stocks);

  const [from_date_int, set_from_date_int] = useState(1704067200000);
  const [to_date_int, set_to_date_int] = useState(1727654400000);
  const [from_date, set_from_date] = useState('2024-01-01');
  const [to_date, set_to_date] = useState('2024-09-30');

  const [isLoading, setLoading] = useState(false);

  const RunStatisticsAPI = async (from_date_int, to_date_int) => {
    setLoading(true);
    console.log("Kjører Analyse API")
    //console.log(from_date_int)
    var portfolio_list = [];
    var portfolio_stock_names = {}
    var portfolio_stock_number = {}
    Object.keys(store_portfolio).map((key, index) => {
      //console.log(key)
      portfolio_list = [...portfolio_list, key];
      portfolio_stock_names[key] = store_portfolio[key].StockName;
      portfolio_stock_number[key] = store_portfolio
      [key].Stocks;
    });

    const portfolio_obj = {
      "portfolio_list": portfolio_list,
      "portfolio_stock_names": portfolio_stock_names,
      "portfolio_stock_amount": portfolio_stock_number
    }

    //console.log(portfolio_obj);

    const data = await PortfolioStatisticsAPI(portfolio_obj, from_date_int, to_date_int);
    //const data = undefined;

    console.log(data);
    if (data !== undefined) {
      var dataItems = data['body']['Statistics'];
      dataItems['Stock_Amount'] = data['body']['Stock_Amount'];

      // console.log(dataItems);
      //setStateStatistics(dataItems);
      // the dispatch sets the dataitems to the AWS Stocklist object!
      dispatch(setPortfolioStatisticsAction(dataItems));
    }
    setLoading(false);
  };

  const handle_from_date_change = (e) => {
    const new_Date = e.target.value
    // console.log(new_Date);
    set_from_date(new_Date);

    const dateStamp = new Date(new_Date).getTime();
    console.log(dateStamp);
    set_from_date_int(dateStamp);

    if (to_date != '' && new_Date > to_date) {
      set_to_date(new_Date);
      set_to_date_int(dateStamp);
    }
  }

  const handle_to_date_change = (e) => {
    const new_Date = e.target.value
    console.log(new_Date);
    set_to_date(new_Date);

    const dateStamp = new Date(new_Date).getTime();
    console.log(dateStamp)
    set_to_date_int(dateStamp);

    if (from_date != '' && new_Date < from_date) {
      set_from_date(new_Date);
      set_from_date_int(dateStamp);
    }
  }

  return (
    <div>
      <h3>Select the analythic period and hit Run Statistics!</h3>
      <div className='date-input-container'>
        <div className='date-input'>
          <label>From date: </label>
          <input
            className='date-input-label'
            type="date"
            id="fromDate"
            value={from_date}
            onChange={handle_from_date_change}
          />
        </div>
        <div className='date-input'>
          <label>To date: </label>
          <input

            className='date-input-label'
            type="date"
            id="fromDate"
            value={to_date}
            onChange={handle_to_date_change}
          />
        </div>
      </div>
      <div className="d-grid gap-2">
        <Button size="lg" disabled={Object.keys(store_portfolio).length === 0} onClick={() => RunStatisticsAPI(from_date_int, to_date_int)}>Run Statistics!</Button>
        <div className="d-flex justify-content-center align-items-center mt-3">
          {isLoading ? <Spinner as="span" animation="border" size="lg" /> : null}
        </div>
      </div>
    </div>
  );
};

// Denne funksjonen tar Porteføljen og sender den til AWS api
const PortfolioStatisticsAPI = async (portfolio_obj, from_date_int, to_date_int) => {

  let payload = {
    "Portfolio_obj": portfolio_obj,
    "TimeFrom": from_date_int,
    "TimeTo": to_date_int
  }
  console.log(payload);
  try {
    const response = await fetch('https://700rdiahvd.execute-api.eu-north-1.amazonaws.com/PROD/stockvolatility',
      {
        method: 'POST', // or 'POST' or other HTTP methods based on your API
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': 'yI9NbrKJp6aTuEl3AGmOQ7w1wgCT9Ko9aj4Mjxjo'
        },
        // Add a request body if needed (for example, for POST requests)
        body: JSON.stringify(payload)
      });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    // console.log(data);
    return (data);

  } catch (error) {
    console.error('Error invoking Lambda function:', error.message);
  }
}

export { GetStockListButton, AnalyzePortfolioButton };


