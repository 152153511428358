// This scirpt is used for handling all the statistics from the stored statistic

// React imports
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

// Import user actions
import { addToPortfolioAction } from '../actions/userActions';

//Import cookio library
import Cookies from 'js-cookie';

// Boot strap functions ----------------
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';

// Chart imports --------------
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

import '../CSS/Statistics.css'

const StockRisk = () => {

  const [state_portfolio_risk, set_risk] = useState(0); //A number that is the total risk of the portfolio
  const [state_portfolio_weights, set_weight] = useState({}); //The weights used for calculating the statistics
  const [state_portfolio_weights_piechart, set_weight_pie] = useState([]); // The weights used in the pie chart
  const [state_portfolio_stock_amount, set_amounts] = useState({}); //An object whith the amount of stocks in for each stock
  const [state_portfolio_total_amount, set_total_amount] = useState(0);
  const [state_portfolio_stock_price, set_price] = useState({}); //latest stock price from the request
  const [state_systematic_risk, set_syst_risk] = useState(0); // The systematic risk is a summarization of the co-variance between the stocks
  const [state_unsystematic_risk, set_unsyst_risk] = useState(0); // Systematic risk is as summarazations of the variance between the stocks
  const [state_var_covar_matrix, set_var_covar_matrix] = useState({}); // 
  const [state_corr_matrix, set_corr_matrix] = useState({});
  const [state_stock_names, set_stock_names] = useState({});

  //const [statistics, set_stuff] = useState({});
  const dispatch = useDispatch();
  const store_statistics = useSelector((state) => state.statistics.PortfolioStatistics);
  const store_portfolio = useSelector((state) => state.portfolio.stocks);
  //The colors in the pie chart  
  const COLORS = [
    '#2C3E50', // Blue-Grey
    '#8E44AD', // Dark Purple
    '#2980B9', // Medium Blue
    '#27AE60', // Dark Green
    '#E74C3C', // Dark Red
    '#F39C12', // Dark Yellow/Gold
    '#D35400', // Burnt Orange
    '#16A085', // Teal
    '#34495E', // Steel Blue
    '#7F8C8D', // Grey
    '#C0392B', // Deep Red
    '#9B59B6', // Medium Purple
    '#F1C40F', // Bright Yellow
    '#E67E22', // Orange
    '#1ABC9C', // Bright Teal
  ];

  // denne funksjonen lagrer ordren til cookie og store som brukes over!
  const savePortfolio = () => {
    console.log('Running save portfolio');
    const store_portfolio_new = { ...store_portfolio };
    Object.keys(store_portfolio).map((key, index) => {
      store_portfolio_new[key] = {
        ...store_portfolio[key],  // Spread the original properties for this stock
        Stocks: state_portfolio_stock_amount[key]  // Update the "Stocks" value
      };
    });
    dispatch(addToPortfolioAction(store_portfolio_new));
  }



  // Handle weight change set the new weights after a change in one of the stocks weights
  const handleweightchange = (key) => {
    console.log("running handle weight")
    const value = state_portfolio_stock_amount[key] * state_portfolio_stock_price[key];
    // console.log(portfolio_stock_price[key]);
    set_weight((state_weights) => ({
      ...state_weights,
      [key]: parseInt(value, 10),
    }));
  }

  // function triggered when changing the number of stocks in the statistics window
  const setPortfolioStocks = (stockSymbol, e) => {
    var value = e.target.value;
    if (value !== "") {
      value = value.replace(/^0+/, "");
    }
    set_amounts((prevPortfolio) => {
      // create a new constant with the updated protfolio and the new value for the stocksymbol
      const updatedPortfolio = {
        ...prevPortfolio,
        [stockSymbol]: value
      };
      return updatedPortfolio
    });
    handleweightchange(stockSymbol);
  };

  //Calculate the weights This useeffect runs whenever the portfolio_stock_amount or portfolio_stock_price and sets new weights
  useEffect(() => {
    const newWeights = {}; // The weights going to calculating the statistics
    const newWeights_pie = []; // the weights in the data structure going to the pie chart
    var new_data = {}; //the new data going into the pie data list

    Object.keys(state_portfolio_stock_amount).forEach((key) => {
      const amount = state_portfolio_stock_amount[key];
      const price = state_portfolio_stock_price[key];
      newWeights[key] = amount * price;
      const weight = (amount * price);
      new_data = { name: key, value: weight };
      newWeights_pie.push(new_data);
    });
    // console.log(newWeights_pie);
    set_weight(newWeights);
    set_weight_pie(newWeights_pie);
    //console.log(newWeights);
  }, [state_portfolio_stock_amount, state_portfolio_stock_price]);

  // This useeffect runs when the statistics store object is changed
  useEffect(() => {


    // Gå ut av denne useEffecten om store_statistics ikke er satt
    if (!store_statistics || Object.keys(store_statistics).length === 0) {
      return; // Exit the useEffect early
    }

    var var_covar_matrix = {};
    var corr_matrix = {};
    var stock_names = {};
    var stock_amount = {};
    var stock_last_price = {};
    var weight_obj = {};

    var_covar_matrix = store_statistics.var_covar_matrix;
    corr_matrix = store_statistics.corr_matrix;
    stock_names = store_statistics.portfolio_stock_names;
    stock_amount = store_statistics.Stock_Amount;
    stock_last_price = store_statistics.last_stockprice;

    set_price(stock_last_price);
    set_amounts(stock_amount);
    Object.keys(stock_amount).map((key, index) => {
      weight_obj[key] = stock_amount[key] * stock_last_price[key]
    });
    set_weight(weight_obj);
    set_stock_names(stock_names);
    set_var_covar_matrix(var_covar_matrix);
    set_corr_matrix(corr_matrix);

  }, [store_statistics])

  // This useeffect is in charge of calculating the portfolio std  any time the weights, 
  useEffect(() => {
    var total_weight = 0;
    var portfolio_variance = 0
    var portfolio_deviation = 0

    if (!state_portfolio_weights || Object.keys(state_portfolio_weights).length === 0) {
      return; // Exit the useEffect early
    }

    var var_covar_matrix = {};
    var_covar_matrix = store_statistics.var_covar_matrix;

    //Find the total weight of the portfolio

    Object.values(state_portfolio_weights).map((value, index) => {
      if (value)
        total_weight = total_weight + parseInt(value, 10);
    });
    // Set the total weight to the state for total weight
    set_total_amount(total_weight);
    //console.log(total_weight);
    var key1_weight = 0
    var key2_weight = 0
    var var_covar_risk = 0
    var var_systematic_risk = 0
    var var_unsystematic_risk = 0

    Object.keys(var_covar_matrix).map((key, index) => {
      Object.keys(var_covar_matrix[key]).map((key2, index) => {
        //console.log(`${key}-${key2}`)
        key1_weight = isNaN(state_portfolio_weights[key]) ? 0 : ((state_portfolio_weights[key]) / total_weight);
        key2_weight = isNaN(state_portfolio_weights[key2]) ? 0 : ((state_portfolio_weights[key2]) / total_weight);
        var_covar_risk = var_covar_matrix[key][key2];
        //console.log(`Weights ${key1_weight} and ${key2_weight}`)
        //console.log(var_covar_risk)
        portfolio_variance = portfolio_variance + ((key1_weight * key2_weight) * (var_covar_risk))

        // Sum up all the systematic and unsystematic risk
        if (key == key2) {
          var_unsystematic_risk = var_unsystematic_risk + ((key1_weight * key2_weight) * (var_covar_risk))
        } else {
          var_systematic_risk = var_systematic_risk + ((key1_weight * key2_weight) * (var_covar_risk))
        }
      })
    });

    portfolio_deviation = ((Math.sqrt(portfolio_variance)) * Math.sqrt(250)).toFixed(3)

    var_systematic_risk = (var_systematic_risk / portfolio_variance) * 100
    var_unsystematic_risk = (var_unsystematic_risk / portfolio_variance) * 100

    //console.log(portfolio_deviation)
    //console.log(`Systematic risk: ${var_systematic_risk} Unsystematic risk: ${var_unsystematic_risk} PortfolioVariance: ${portfolio_variance}`)

    set_risk(portfolio_deviation);
    set_syst_risk(var_systematic_risk);
    set_unsyst_risk(var_unsystematic_risk);
  }, [state_portfolio_weights]); //End of the use effect function

  var riskTable =
    <div>
      <h2>Portfolio Standard-Deviation Calculation</h2>
      <table className="PortfolioTable">
        <thead>
          <tr>
            <th>Stock</th>
            <th>Yearly Standard Deviation</th>
            <th>Amount of Stocks</th>
            <th>Stock Price</th>
            <th>Weight/Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(state_var_covar_matrix).map((key, index1) => (
            Object.keys(state_var_covar_matrix[key]).map((key2, index2) => (
              key == key2 ? (

                <tr key={key}>
                  <td>{state_stock_names[key]}</td>
                  <td>{(Math.sqrt(state_var_covar_matrix[key][key2]) * Math.sqrt(250)).toFixed(3)}</td>
                  <td>
                    <input
                      type="number"
                      value={state_portfolio_stock_amount[key] || 0}
                      min="0"
                      onChange={(e) => setPortfolioStocks(key, e)}
                    />
                  </td>
                  <td>
                    {state_portfolio_stock_price[key] || 0}
                  </td>
                  <td>
                    {(state_portfolio_weights[key] !== undefined && state_portfolio_weights[key] !== null) ? state_portfolio_weights[key].toFixed(0) : null}
                  </td>
                </tr>
              ) : null
            ))
          ))}
          <tr className="PortfolioTable_totals">
            <td colSpan="4" className="PortfolioTable_totals">Total Amount</td>
            <td className="PortfolioTable_totals"> {state_portfolio_total_amount.toFixed(0)}$</td>
          </tr>
        </tbody>
      </table>
      <br></br>
      <Button disabled={Object.keys(state_portfolio_weights).length === 0} onClick={() => savePortfolio()}>Save Portfolio!</Button>

      <Container className='Portfolio_Risk_Container'>
        <Row lg={20}>
          <Col>
            <p className='Portfolio_Risk_Text'>Portfolio Standard Deviation: </p>
            <p className='Portfolio_Risk_Result'>{state_portfolio_risk}</p>
          </Col>
        </Row>
      </Container>
      <Carousel data-bs-theme="dark">
        <Carousel.Item interval={30000}>
          <h2>Systematic VS Unsystematic Risk</h2>
          <Container className='Portfolio_Risk_Container'>
            <Row>
              <Col>
                <p className='state_Portfolio_Risk_Text'>Systematic Risk: {state_systematic_risk.toFixed(2)}</p>
                <p className='state_Portfolio_Risk_Text'>UnSystematic Risk: {state_unsystematic_risk.toFixed(2)}</p>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center">
                <PieChart width={200} height={200}>
                  <Pie
                    data={[{ name: 'Systematic', value: state_systematic_risk }, { name: 'UnSystematic', value: state_unsystematic_risk }]}
                    cx="50%"
                    cy="50%"
                    labelLine={true}
                    fill="#8884d8"
                    dataKey="value"
                    nameKey="name"
                  >
                    <Cell key='Systematic' fill="#6df802"></Cell>
                    <Cell key='UnSystematic' fill="#fb0000"></Cell>
                  </Pie>
                </PieChart>
              </Col>
            </Row>
          </Container>
          <br></br>
        </Carousel.Item>
        <Carousel.Item interval={30000}>
          <h2>Stock Correlation Matrix</h2>
          <table className="PortfolioTable_Corr">
            <thead>
              <tr>
                <th></th>
                {Object.keys(state_corr_matrix).map((key, index) => (
                  <th>{state_stock_names[key]}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(state_corr_matrix).map((key1, index1) => (
                <tr>
                  <td>{state_stock_names[key1]}</td>
                  {Object.keys(state_corr_matrix).map((key2, index2) => (
                    <td
                      className={`Corr_${Math.round((Math.round(state_corr_matrix[key1][key2].toFixed(1) / 0.2) * 0.2) * 10)}`}
                      key={index1 + ":" + index2}
                    >
                      {state_corr_matrix[key1][key2].toFixed(4)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <br></br><br></br>
        </Carousel.Item>
        <Carousel.Item interval={30000}>
          <h2>Stock Distrubution</h2>
          <Container className='Portfolio_Risk_Container'>
            <Row>
              <Col>
                {state_portfolio_weights_piechart.map((entry, index) => (
                  <p key={`cell-${index}`} >
                    {entry.name} {(entry.value).toFixed(2)}$
                  </p>
                ))}
              </Col>
            </Row>
            <Row>
              <Col>
                <PieChart width={330} height={330}>
                  <Pie
                    data={state_portfolio_weights_piechart}
                    cx="50%"
                    cy="50%"
                    labelLine={true}
                    label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                    outerRadius={70}  /* Increase the outerRadius to fit better */
                    fill="#8884d8"
                    dataKey="value"
                    nameKey="name"
                  >
                    {state_portfolio_weights_piechart.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </Col>
            </Row>
          </Container>
          <br></br>
        </Carousel.Item>
      </Carousel>
    </div>


  if (store_statistics == undefined) {
    console.log("No Statistics!");
    riskTable = <div>No statistics set!</div>
  }

  return riskTable
}

export default StockRisk