import React, { useState, useEffect } from 'react';
import "../CSS/App.css";
import { useSelector } from 'react-redux';

// User action
import { useDispatch } from 'react-redux';
import { addToPortfolioAction, deletePortfolioAction } from '../actions/userActions';

// Cookie library -------
import Cookies from 'js-cookie';

// Import Bootstrap
import Button from 'react-bootstrap/Button';

// Checklist er listen over alle aksjene som ligger i portefølgen
const Checklist = () => {
  // Set the states ###################################
  // this is the state for the portfolio
  // const [state_portfolio, setPortfolio] = useState({});

  //This is the stocks from the store!Not in use?
  const dispatch = useDispatch();
  const store_portfolio = useSelector((state) => state.portfolio.stocks);
  const store_stocks = useSelector((state) => state.availableStocks.AWSStocklist);

  // Slicers from and to
  const [fromSlicer, setFromSlicer] = useState(0);
  const [toSlicer, setToSlicer] = useState(10);

  // A search value for the stocks
  const [search_value, set_search_value] = useState('');

  // The number of stocks in a portfolio
  // wraped thisone into portfolio object
  // const [portfolio_stocks, set_stocks] = useState({});

  // The functions ####################################
  // The slicer functions
  const loadNextTen = () => {
    setFromSlicer(fromSlicer + 10);
    setToSlicer(toSlicer + 10);
  };
  const loadLastTen = () => {
    if (fromSlicer > 0) {
      setFromSlicer(fromSlicer - 10);
      setToSlicer(toSlicer - 10);
    }
  };

  // This function is for setting the search filter
  const handleSearch = (e) => {
    const value = e.target.value;
    set_search_value(value);
  }

  // Adds the stock to the portfolio state. Not the store
  const addToPortfolio = (stock) => {
    //setPortfolio is used from the state, and sets the stock to the portfolio state
    // console.log(stock);
    const new_stock = {
      [stock.StockSymbol]: {
        ...stock,
        Stocks: 0
      }
    }
    // console.log(new_stock);
    const updatedPortfolio = { ...store_portfolio, ...new_stock };
    // console.log(updatedPortfolio);
    dispatch(addToPortfolioAction(updatedPortfolio));
    Cookies.set('Portfolio', JSON.stringify(updatedPortfolio), { expires: 7 });
  };

  // Funksjon for å fjerne aksjer som er lagt til i porteføljen!
  const removeFromPortfolio = (stock) => {
    const updatedPortfolio = { ...store_portfolio };
    delete updatedPortfolio[stock.StockSymbol];
    // delete updatedPortfolio.StockSymbol
    dispatch(deletePortfolioAction(updatedPortfolio));
    Cookies.set('Portfolio', JSON.stringify.updatedPortfolio, { expires: 7 });
  };

  // this function change the number of stock in the portfolio object
  const setPortfolioStocks = (stockSymbol, e) => {
    var value = e.target.value;
    if (value !== "") {
      value = value.replace(/^0+/, "");
    }
    // create a new object with the new stock symbol as key, with the stock as an object!
    const updatedStocks = {
      ...store_portfolio[stockSymbol],
      Stocks: value
    };
    const updatedPortfolio = {
      ...store_portfolio,
      [stockSymbol]: updatedStocks
    };
    // console.log(updatedPortfolio);
    dispatch(addToPortfolioAction(updatedPortfolio));
    //console.log('Coockie')
    console.log(updatedPortfolio);
    Cookies.set('Portfolio', JSON.stringify(updatedPortfolio), { expires: 7 });
  };


  // Set the portfolio object from the cookie
  const setPortfolioFromCookie = (stored_portfolio) => {
    console.log('running setPortfolioFromCookie');
    const updatedPortfolio = { ...stored_portfolio };
    dispatch(addToPortfolioAction(updatedPortfolio));
  };


  useEffect(() => {
    const stored_portfolio_str = Cookies.get('Portfolio');
    console.log('UseEffect Portfolio:');
    console.log(stored_portfolio_str);

    if (stored_portfolio_str !== 'undefined') {
      try {
        console.log(stored_portfolio_str);
        const stored_portfolio = JSON.parse(stored_portfolio_str);
        setPortfolioFromCookie(stored_portfolio);
      } catch (error) {
        console.error('Error parsing stored portfolio:', error);
      }
    }
  }, []); // Dependency array includes StoreStocks



  const stockChecklist =
    <div>
      <h2>StockList</h2>
      <input
        type="text"
        placeholder="Filter list..."
        value={search_value}
        onChange={handleSearch}
      />
      <br></br>
      <Button variant="outline-primary" onClick={() => loadLastTen()}>Last 10 stocks</Button>
      <Button variant="outline-primary" onClick={() => loadNextTen()}>Next 10 stocks</Button>
      <table className="PortfolioTable">
        <thead>
          <tr>
            <th>Number</th>
            <th>Stock Name</th>
            <th>Country</th>
            <th>Add to portfolio</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(store_stocks)
            .filter((obj) =>
              obj.StockName.toLowerCase().includes(search_value.toLowerCase())
            )
            .slice(fromSlicer, toSlicer)
            .map((obj, index) => (
              <tr key={obj.StockSymbol}>
                <td>{index + fromSlicer + 1}</td>
                <td>{obj.StockName}</td>
                <td>{obj.CountryCode}</td>
                <td>
                  <Button variant="outline-primary" onClick={() => addToPortfolio(obj)}> Add </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <h2>PortFolio</h2>
      <table className="PortfolioTable">
        <thead>
          <tr>
            <th>Nr</th>
            <th>Stock</th>
            <th>Country Code</th>
            <th>Number of Stocks</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(store_portfolio).map((obj, index) => (
            <tr key={obj.StockSymbol}>
              <td>{index + 1}</td>
              <td>{obj.StockName}</td>
              <td>{obj.CountryCode}</td>
              <td>
                <input
                  type="number"
                  value={obj.Stocks || 0}
                  min="0"
                  onChange={(e) => setPortfolioStocks(obj.StockSymbol, e)}
                />
              </td>
              <td>
                <Button variant="outline-primary" onClick={() => removeFromPortfolio(obj)}>Remove</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br></br>
    </div>


  //console.log(StoreStocks)


  return (stockChecklist)



};

export default Checklist;
